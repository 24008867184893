body {
  background-color: #f8f9fa;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 30px;
  text-transform: uppercase;
}

form {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

form label {
  font-size: 1rem;
  font-weight: 600;
}

form .form-control {
  box-shadow: none;
}

form button {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.card {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card-header {
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #343a40;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
}

.card-body pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.alert {
  font-weight: 600;
}
